import React from 'react';

function PictureItem(props) {
  return (
    <>
      <li className='picture__item'>
          <figure>
            <img
              className='picture__item__img'
              alt='Kishuverse'
              src={props.src}
            />
          </figure>
          <div className='picture__item__info'>
          </div>
      </li>
    </>
  );
}

export default PictureItem;
