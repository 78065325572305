import React from 'react';

function CurrentMint(props) {
  return (
            <img
              className='picture__item__img2'
              alt='Kishuverse'
              src={props.src}
            />
  );
}

export default CurrentMint;
