import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
            KISHUVERSE
            </Link>
          </div>
          <small className='website-rights'>Kishuverse V0.1 Copyright © 2022, Kishu Inu</small>
          <div className='social-icons'>
            <button
            style={{background:"black", cursor: "pointer", border:"none"}}
              className='social-icon-link discord'
              target='_blank'
              aria-label='facebook'
              onClick={(e) => {
                e.preventDefault();
                window.open("https://discord.gg/kishuinucommunity", "_blank");
              }}
            >
              <i className='fab fa-discord' />
            </button>
            <button
            style={{background:"black", cursor: "pointer", border:"none"}}
              className='social-icon-link instagram'
              target='_blank'
              aria-label='Instagram'
              onClick={(e) => {
                e.preventDefault();
                window.open("https://www.instagram.com/inukishu/", "_blank");
              }}
            >
              <i className='fab fa-instagram' />
            </button>
            <button
            style={{background:"black", cursor: "pointer", border:"none"}}
              className='social-icon-link reddit'
              target='_blank'
              aria-label='reddit'
              onClick={(e) => {
                e.preventDefault();
                window.open("https://www.reddit.com/r/KishuInu/", "_blank");
              }}
            >
              <i className='fab fa-reddit' />
            </button>
            <button
            style={{background:"black", cursor: "pointer", border:"none"}}
              className='social-icon-link kishu'
              target='_blank'
              aria-label='kishu'
              onClick={(e) => {
                e.preventDefault();
                window.open("https://kishu.com/", "_blank");
              }}
            >
              <img
              style={{width:"45px"}}
              src='/images/kishulogo.png'></img>
            </button>
            <button
            style={{background:"black", cursor: "pointer", border:"none"}}
              className='social-icon-link opensea'
              target='_blank'
              aria-label='opensea'
              onClick={(e) => {
                e.preventDefault();
                window.open("https://opensea.io/collection/kishuverse", "_blank");
              }}
            >
              <img
              style={{width:"24px", paddingTop: "5px"}}
              src='/images/opensea.png'></img>
            </button>
            <button
            style={{background:"black", cursor: "pointer", border:"none"}}
              className='social-icon-link twitter'
              target='_blank'
              aria-label='Twitter'
              onClick={(e) => {
                e.preventDefault();
                window.open("https://twitter.com/inukishu", "_blank");
              }}
            >
              <i className='fab fa-twitter' />
            </button>
            <button
            style={{background:"black", cursor: "pointer", border:"none"}}
              className='social-icon-link telegram'
              target='_blank'
              aria-label='Telegram'
              onClick={(e) => {
                e.preventDefault();
                window.open("https://t.me/kishuinucommunity", "_blank");
              }}
            >
              <i className='fab fa-telegram' />
            </button>
            
            
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
