import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from "./components/redux/store";
import { Provider } from "react-redux";
import "./components/styles/reset.css";
import "./components/styles/theme.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );

  reportWebVitals();