import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import CurrentMint from "../CurrentMint";


function ProjectM() {
  const dispatch = useDispatch();
  const empty = "-"

  return (
    <div className="mint__container">
      <div className="mint__container">
            <div className="sign">
              <div className="fast-flicker">PR</div>
              <div className="flicker">OJ</div>
              <div className="fast-flicker">ECT</div>
              <div className="flicker">{empty}</div>
              <div className="fast-flicker">M</div>
            </div>
            <div className="connect__wrapper">
              <CurrentMint src="images/ProjectM.png" />
              </div></div>
    </div>       
  );};

export default ProjectM;
