import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import PictureItem from './PictureItem';
import { Button } from './Button';

const arrowleft = " <<"
const arrowright = ">> "
const empty = "-"

function Cards() {
  return (
    <div className='cards' >
      <div className='cards__container2'  >
            <div className='cards__wrapper2'  >
      <PictureItem
      src='images/img-10.gif'
      />
        </div>
        </div>
      <div className='cards__container'  >
        <div className='cards__wrapper'  >
          <button
          className="btnn2"
          onClick={(e) => {
            e.preventDefault();
            window.location='.#/Mint'
          }}
          >
            <div className="sign2">
            <div className="flicker2">{arrowright} </div>
              <div className="fast-flicker2">M</div>
              <div className="flicker2">IN</div>
              <div className="fast-flicker2">T</div>
              <div className="flicker2">
                {arrowleft}
                </div>
              </div>
          </button>
          <button
          className="btnn2"
          onClick={(e) => {
            e.preventDefault();
            window.location='.#/Rarity'
          }}
          >
          <div className="sign2">
          <div className="flicker3">{arrowright} </div>
              <div className="fast-flicker3">R</div>
              <div className="flicker3">AR</div>
              <div className="fast-flicker3">ITY</div>
              <div className="flicker3">
                {arrowleft}
                </div>
              </div>
              </button>
        </div>
      </div>
    </div>
  );
}

export default Cards;

