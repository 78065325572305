import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { HashRouter, Router, Switch, Route } from 'react-router-dom';
import Mint from './components/pages/Mint';
import Rarity from './components/pages/Rarity';
import Footer from './components/Footer';
import ProjectM from './components/pages/ProjectM';

function App() {
  return (
    <>
      <HashRouter hashType='slash'>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/mint' component={Mint} />
          <Route path='/rarity' component={Rarity} />
          <Route path='/projectm' component={ProjectM} />
        </Switch>
        <Footer />
      </HashRouter>
    </>
  );
}

export default App;
